import { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useMediaQuery } from 'react-responsive';
import { useClienti } from "../hooks/useClienti";
import { useDebounce } from "../hooks/useDebounce";
import { useAuth } from "../context/Auth";
import useEnsureLogin from "../hooks/useEnsureLogin";
import pb from "../lib/pocketbase";

export default function Clienti() {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const { mutate, isError } = useMutation(inserisciCliente);
    const [filtro, setFiltro] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [currentCli, setCurrentCli] = useState(null);
    const [clienteInModifica, setClienteInModifica] = useState(null);
    const modPag = ["Seleziona un metodo di pagamento", "Rimessa diretta a vista netto", "Rimessa diretta a 30gg. d.f.", "Rimessa diretta a 60gg. d.f.", "Rimessa diretta a 90gg. d.f.", "Rimessa diretta a 120gg. d.f.", "Rimessa diretta a vista fine mese netto", "Rimessa diretta a 30gg. f.m.", "Rimessa diretta a 60gg. f.m.", "Rimessa diretta a 90gg. f.m.", "Rimessa diretta a 120gg. f.m.", "Bonifico bancario a vista data fattura netto", "Bonifico bancario a 30gg. d.f.", "Bonifico bancario a 60gg. d.f.", "Bonifico bancario a 90gg. d.f.", "Bonifico bancario a 120gg. d.f.", "Bonifico bancario a vista data fattura f.m.", "Bonifico bancario a 30gg. f.m.", "Bonifico bancario a 60gg. f.m.", "Bonifico bancario a 90gg. f.m.", "Bonifico bancario a 120gg. f.m.", "Ri.Ba. a 30gg. f.m.", "Ri.Ba. a 60gg. f.m.", "Ri.Ba. a 90gg. f.m.", "Ri.Ba. a 120gg. f.m.", "Rid a 30gg. f.m.", "Rid a 60gg. f.m.", "Rid a 90gg. f.m.", "Rid a 120gg. f.m.", "Contanti", "Assegno", "Carta di credito", "Bancomat", "Compensazione fatture"];
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const user = useAuth();

    // Filtro Clienti
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Gestione filtro servizi con sovrascrittura data isMobile
    const filtroClienti = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    const { data: clienti, isLoading: isClientiLoading,
        isError: isClientiError, error: clientiError,
        refresh: refreshClienti
    } = useClienti({ user });

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    function handleShow() {
        setShow(!show);
        reset();
    }

    function indietroTasto() {
        setClienteInModifica(null);
        handleShow();
    }

    function onClickModificaCliente(id) {
        setCurrentCli(id);
        setClienteInModifica(clienti.find(cliente => cliente.id === id));
        handleShow();
    }

    async function modificaCliente(data) {
        await pb.collection('cliente').update(currentCli, {
            ...data,
            "user": userDatabaseOn
        });
        setClienteInModifica(null);
        refreshClienti();
        handleShow();
    }

    async function eliminaCliente() {
        await pb.collection('cliente').delete(currentCli);
        refreshClienti();
        setCurrentCli(null);
    }

    async function sulClick(data) {
        !!clienteInModifica ? modificaCliente(data) : mutate(data);
    }

    async function inserisciCliente(data) {
        await pb.collection('cliente').create({
            ...data,
            "user": userDatabaseOn
        });
        refreshClienti();
        handleShow();
    }

    return (<>
        {!show && <>
            <div className="hidden lg:block self-stretch">
                <div className="collapse collapse-plus bg-base-700 items-center">
                    <div>
                        <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo cliente </button>
                    </div>

                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                        Mostra/Nascondi filtri
                    </div>

                    <div className="collapse-content col-span-2">
                        <form className="grid grid-cols-2 gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Ragione sociale/Alias: </label>
                                <input {...formFiltro.register("ragione_sociale_alias")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Ragione sociale/Alias" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                                <input {...formFiltro.register("nome")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Nome" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                                <input {...formFiltro.register("cognome")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Cognome" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                                <input {...formFiltro.register("telefono")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Telefono" />
                            </div>

                            <div className="md:flex md:items-center">
                                <label className="md:w-1/3 text-right mr-2"> Mail:</label>
                                <input {...formFiltro.register("mail")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Mail" />
                            </div>

                            <button type="reset" className="btn btn-outline btn-md justify-self-center col-span-2" onClick={formFiltro.reset}> Pulisci filtri </button>

                        </form>
                    </div>
                </div>
            </div>
            {isClientiLoading && <div className="m-4 loading loading-spinner loading-md">Caricamento...</div>}

            <table className="hidden lg:table table w-screen table-compact table-zebra table-fixed mx-2">
                <thead>
                    <tr>
                        <td> Ragione Sociale </td><td> Nome </td><td> Cognome </td><td> Telefono </td><td> Mail </td><td> Note </td><td> </td>
                    </tr>
                </thead>
                <tbody>
                    {clienti && clienti.map((cliente, index) => <tr key={index}>
                        <td className="break-normal"> <p> {cliente.ragione_sociale_alias} </p> </td>
                        <td className="break-normal"> <p> {cliente.nome} </p> </td>
                        <td className="break-normal"> <p> {cliente.cognome} </p> </td>
                        <td className="break-normal"> <p> {cliente.telefono} </p> </td>
                        <td className="break-normal"> <p> {cliente.mail} </p> </td>
                        <td className="break-normal"> <p> {cliente.note} </p> </td>
                        <td> <button className="btn btn-outline btn-xs" onClick={(() => onClickModificaCliente(cliente.id))}> Modifica </button> <button className="btn btn-outline btn-xs hover:bg-red-700" onClick={() => { document.getElementById('modalElimina').showModal(); setCurrentCli(cliente.id) }}> Elimina </button> </td>
                    </tr>)}
                </tbody>
            </table>

            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}
            {/* visualizzazione mobile */}

            {isMobile && <div className="mb-4 contents">
                <div className="my-2">
                    <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo cliente </button>
                </div>

                {isClientiLoading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                {isClientiError && <div> Errore: {clientiError.message} </div>}
                {clienti && clienti.map((cliente) =>
                    <div onClick={(() => onClickModificaCliente(cliente.id))} key={cliente.id} className="self-stretch border border-black lg:hidden">
                        <div className="grid grid-flow-row grid-cols-2 px-2">

                            <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"><path stroke="none" d="M0 0h24v24H0z"/><path d="M10 9a2 2 0 1 0 4 0 2 2 0 0 0-4 0M4 8V6a2 2 0 0 1 2-2h2M4 16v2a2 2 0 0 0 2 2h2M16 4h2a2 2 0 0 1 2 2v2M16 20h2a2 2 0 0 0 2-2v-2M8 16a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2"/></svg> 
                                <p className="ml-1 break-normal">{cliente.ragione_sociale_alias}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-phone"><path stroke="none" d="M0 0h24v24H0z"/><path d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"/></svg>  
                                <p className="ml-1 break-normal">{cliente.telefono}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/></svg>                                 
                                <p className="ml-1 break-normal">{cliente.nome}</p>
                            </div>

                            <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/></svg>  
                                <p className="ml-1 break-normal">{cliente.cognome}</p>
                            </div>

                        </div>
                    </div>)}
            </div>}

            <dialog id="modalElimina" className="modal-middle rounded-md">
                <form method="dialog">
                    <p className="p-5"> Sei sicuro di voler eliminare il cliente?</p>
                    <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaCliente())}> Conferma </button>
                    <button className="btn btn-outline btn-xs m-3"> Annulla </button>
                </form>
            </dialog>
        </>}

        {/* Inserimento/Modifica dati PC*/}
        {/* Inserimento/Modifica dati PC*/}
        {/* Inserimento/Modifica dati PC*/}

        {show && !isMobile && <div className="NuovoCliente">

            {isError && <p> Formato mail errato </p>}

            <div className="divFormInserisci w-full max-w-xl m-auto">
                <form className="w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-4" onSubmit={handleSubmit(sulClick)}>

                    <div className="flex flex-col justify-center items-center mb-6">
                        <h1 className="mt-4 text-2xl font-semibold"> {!!clienteInModifica ? "MODIFICA I DATI DEL CLIENTE" : "INSERISCI I DATI DEL CLIENTE"} </h1>
                    </div>

                    <div className="divider" />

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Ragione sociale/Alias: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Ragione sociale/Alias" {...register("ragione_sociale_alias")} defaultValue={clienteInModifica?.ragione_sociale_alias ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={clienteInModifica?.cognome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={clienteInModifica?.nome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nazione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nazione" {...register("nazione")} defaultValue={clienteInModifica?.nazione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={clienteInModifica?.regione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={clienteInModifica?.provincia ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={clienteInModifica?.cap ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Città: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={clienteInModifica?.citta ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={clienteInModifica?.indirizzo ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={clienteInModifica?.telefono ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={clienteInModifica?.mail ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> PEC: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="PEC" {...register("mail_pec")} defaultValue={clienteInModifica?.mail_pec ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Sito web: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Sito web" {...register("sito_web")} defaultValue={clienteInModifica?.partita_iva ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> P. Iva: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="P. Iva" {...register("partita_iva")} defaultValue={clienteInModifica?.sito_web ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice fiscale: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice fiscale" {...register("codice_fiscale")} defaultValue={clienteInModifica?.codice_fiscale ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Modalità di pagamento: </label>
                        <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("modalita_pagamento")} defaultValue={clienteInModifica?.modalita_pagamento ?? ""} >
                            {modPag.map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> È fornitore: </label>
                        <input className="checkbox" type="checkbox" {...register("fornitore")} defaultChecked={clienteInModifica?.fornitore ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice PA/SDI: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice PA/SDI" {...register("codice_PA_SDI")} defaultValue={clienteInModifica?.codice_PA_SDI ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Note: </label>
                        <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={clienteInModifica?.note ?? ""} />
                    </div>

                    <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                    </div>

                </form>
            </div>
        </div>}

        {/* Inserimento/Modifica dati mobile*/}
        {/* Inserimento/Modifica dati mobile*/}
        {/* Inserimento/Modifica dati mobile*/}

        {show && isMobile && <div className="NuovoCliente">

            {isError && <p> Formato mail errato </p>}

            <div className="w-screen m-auto">
                <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(sulClick)}>

                    <div className="flex flex-col justify-center items-center mb-6">
                        <h1 className="mt-4 text-2xl font-semibold"> {!!clienteInModifica ? "MODIFICA I DATI DEL CLIENTE" : "INSERISCI I DATI DEL CLIENTE"} </h1>
                    </div>

                    <div className="divider" />

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Ragione sociale/Alias: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" required placeholder="Ragione sociale/Alias" {...register("ragione_sociale_alias")} defaultValue={clienteInModifica?.ragione_sociale_alias ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={clienteInModifica?.cognome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={clienteInModifica?.nome ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Nazione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nazione" {...register("nazione")} defaultValue={clienteInModifica?.nazione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={clienteInModifica?.regione ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={clienteInModifica?.provincia ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={clienteInModifica?.cap ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Città: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={clienteInModifica?.citta ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={clienteInModifica?.indirizzo ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={clienteInModifica?.telefono ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={clienteInModifica?.mail ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> PEC: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="PEC" {...register("mail_pec")} defaultValue={clienteInModifica?.mail_pec ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Sito web: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Sito web" {...register("sito_web")} defaultValue={clienteInModifica?.partita_iva ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> P. Iva: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="P. Iva" {...register("partita_iva")} defaultValue={clienteInModifica?.sito_web ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice fiscale: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice fiscale" {...register("codice_fiscale")} defaultValue={clienteInModifica?.codice_fiscale ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Modalità di pagamento: </label>
                        <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" {...register("modalita_pagamento")} defaultValue={clienteInModifica?.modalita_pagamento ?? ""} >
                            {modPag.map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> È fornitore: </label>
                        <input className="checkbox" type="checkbox" {...register("fornitore")} defaultChecked={clienteInModifica?.fornitore ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Codice PA/SDI: </label>
                        <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Codice PA/SDI" {...register("codice_PA_SDI")} defaultValue={clienteInModifica?.codice_PA_SDI ?? ""} />
                    </div>

                    <div className="md:flex md:items-center mb-6">
                        <label className="md:w-1/3 text-right mr-2"> Note: </label>
                        <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={clienteInModifica?.note ?? ""} />
                    </div>

                    <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                        <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                    </div>

                </form>
            </div>
        </div>}
    </>
    );
}