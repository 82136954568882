import { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useMediaQuery } from 'react-responsive';
import { useReferenti } from "../hooks/useReferenti";
import { useClienti } from "../hooks/useClienti";
import { useDebounce } from "../hooks/useDebounce";
import { useAuth } from "../context/Auth";
import useEnsureLogin from "../hooks/useEnsureLogin";
import pb from "../lib/pocketbase";

export default function Referente() {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const { mutate, isError, error } = useMutation(inserisciReferente);
    const [filtro, setFiltro] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [currentRef, setCurrentRef] = useState(null);
    const [referenteInModifica, setReferenteInModifica] = useState(null);
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const user = useAuth();

    // Filtro Referenti
    const formFiltro = useForm({ mode: "onChange" });
    const watchFiltroDebounced = useDebounce(useWatch({ control: formFiltro.control }), 200);

    // Gestione filtro servizi con sovrascrittura data isMobile
    const filtroReferenti = useMemo(() => {
        return watchFiltroDebounced
    }, [watchFiltroDebounced]);

    const { data: referenti, isLoading: isReferentiLoading,
        isError: isReferentiError, error: referentiError,
        refresh: refreshReferenti
    } = useReferenti({ user });

    const { data: clienti, isLoading: isClientiLoading,
        isError: isClientiError, error: clientiError,
        refresh: refreshClienti
    } = useClienti({ user });

    // Utente per le query
    const userDatabaseOn = useMemo(() => {
        // if (user === undefined) return "";
        return user.tag === "SuperAdmin" ? user.id : user.creato_da;
    }, [user]);

    function handleShow() {
        setShow(!show);
        reset();
    }

    function indietroTasto() {
        setReferenteInModifica(null);
        handleShow();
    }

    function onClickModificaReferente(id) {
        setCurrentRef(id);
        setReferenteInModifica(referenti.find(referente => referente.id === id));
        handleShow();
    }

    async function modificaReferente(data) {
        await pb.collection('referente').update(currentRef, {
            ...data,
            "user": userDatabaseOn
        });
        setReferenteInModifica(null);
        refreshReferenti();
        handleShow();
    }

    async function eliminaReferente() {
        await pb.collection('referente').delete(currentRef);
        refreshReferenti();
        setCurrentRef(null);
    }

    async function sulClick(data) {
        !!referenteInModifica ? modificaReferente(data) : mutate(data);
    }

    async function inserisciReferente(data) {
        await pb.collection('referente').create({
            ...data,
            "user": userDatabaseOn
        });
        refreshReferenti();
        handleShow();
    }

    return (
        <>
            {!show && <>
                <div className="hidden lg:block self-stretch">
                    <div className="collapse collapse-plus bg-base-700 items-center">
                        <div>
                            <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo referente </button>
                        </div>

                        <input type="checkbox" />
                        <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                            Mostra/Nascondi filtri
                        </div>

                        <div className="collapse-content col-span-2">
                            <form className="grid grid-cols-2 gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Cliente: </label>
                                    <input {...formFiltro.register("cliente")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Cliente" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                                    <input {...formFiltro.register("nome")} className="md:w-2/3 input input-bordered input-xs" type="text" placeholder="Nome" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> Mail:</label>
                                    <input {...formFiltro.register("mail")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="Mail" />
                                </div>

                                <div className="md:flex md:items-center">
                                    <label className="md:w-1/3 text-right mr-2"> In fattura: </label>
                                    <input {...formFiltro.register("in_fattura")} className="md:w-2/3 input input-bordered input-xs" type="date" placeholder="In fattura" />
                                </div>

                                <button type="reset" className="btn btn-outline btn-md justify-self-center col-span-2" onClick={formFiltro.reset}> Pulisci filtri </button>

                            </form>
                        </div>
                    </div>
                </div>
                {isReferentiLoading && <div className="m-4 loading loading-spinner loading-md">Caricamento...</div>}

                <table className="hidden lg:table table w-screen table-compact table-zebra table-fixed mx-2">
                    <thead>
                        <tr>
                            <td> Cliente </td><td> Nome </td><td> Mail </td><td> In fattura </td><td> Note </td><td> </td>
                        </tr>
                    </thead>
                    <tbody>
                        {referenti && referenti.map((referente) => <tr key={referente.id}>
                            <td className="break-normal"> <p> {clienti && clienti.find(cliente => cliente.id === referente.cliente).ragione_sociale_alias} </p></td>
                            <td className="break-normal"> <p> {referente.nome} </p></td>
                            <td className="break-normal"> <p> {referente.mail} </p></td>
                            <td className="break-normal"> <p> {referente.in_fattura} </p></td>
                            <td className="break-normal"> <p> {referente.note} </p></td>
                            <td> <button className="btn btn-outline btn-xs" onClick={(() => onClickModificaReferente(referente.id))}> Modifica </button> <button className="btn btn-outline btn-xs hover:bg-red-700" onClick={() => { document.getElementById('modalElimina').showModal(); setCurrentRef(referente.id) }}> Elimina </button> </td>
                        </tr>)}
                    </tbody>
                </table>

                {/* visualizzazione mobile */}
                {/* visualizzazione mobile */}
                {/* visualizzazione mobile */}

                {isMobile && <div className="mb-4 contents">
                    <div className="my-2">
                        <button onClick={handleShow} className="btn btn-outline btn-sm"> Inserisci nuovo referente </button>
                    </div>

                    {isReferentiLoading && <div><span className="p-4 loading loading-spinner loading-md"></span></div>}
                    {isReferentiError && <div> Errore: {referentiError.message} </div>}
                    {referenti && referenti.map((referente) =>
                        <div onClick={(() => onClickModificaReferente(referente.id))} key={referente.id} className="self-stretch border border-black lg:hidden">
                            <div className="grid grid-flow-row grid-cols-2 px-2">

                                <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="icon icon-tabler icons-tabler-outline icon-tabler-user-hexagon"><path stroke="none" d="M0 0h24v24H0z"/><path d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM6.201 18.744A4 4 0 0 1 10 16h4a4 4 0 0 1 3.798 2.741"/><path d="M19.875 6.27c.7.398 1.13 1.143 1.125 1.948v7.284c0 .809-.443 1.555-1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1-2.184 0l-6.75-4.27A2.225 2.225 0 0 1 3 15.502V8.217c0-.809.443-1.554 1.158-1.947l6.75-3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"/></svg>  
                                    <p className="ml-1 break-normal">{clienti.find(cliente => cliente.id === referente.cliente).ragione_sociale_alias}</p>
                                </div>

                                <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/></svg> 
                                    <p className="ml-1 break-normal">{referente.nome}</p>
                                </div>

                                <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-mail"><path stroke="none" d="M0 0h24v24H0z"/><path d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7z"/><path d="m3 7 9 6 9-6"/></svg> 
                                    <p className="ml-1 break-normal">{referente.mail}</p>
                                </div>

                                <p></p>

                                <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" className="icon icon-tabler icons-tabler-outline icon-tabler-receipt-2"><path stroke="none" d="M0 0h24v24H0z"/><path d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2-2 2-2-2-2 2-2-2-3 2"/><path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1.5m0-9V8"/></svg> 
                                    <p className="ml-1 break-normal">{referente.in_fattura}</p>
                                </div>

                            </div>
                        </div>)}
                </div>}

                <dialog id="modalElimina" className="modal-middle rounded-md">
                    <form method="dialog">
                        <p className="p-5"> Sei sicuro di voler eliminare il referente?</p>
                        <button className="btn btn-outline btn-xs m-3" onClick={(() => eliminaReferente())}> Conferma </button>
                        <button className="btn btn-outline btn-xs m-3"> Annulla </button>
                    </form>
                </dialog>
            </>}

            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}
            {/* Inserimento/Modifica dati PC*/}

            {show && !isMobile && <div className="NuovoReferente">

                {isError && <p> Formato mail errato </p>}

                <div className="divFormInserisci w-full max-w-xl m-auto">
                    <form className="w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> {!!referenteInModifica ? "MODIFICA I DATI DEL REFERENTE" : "INSERISCI I DATI DEL REFERENTE"} </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2">Cliente: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("cliente")} defaultValue={referenteInModifica?.cliente ?? ""} >
                                <option key="0" value="">
                                    Seleziona il cliente
                                </option>
                                {clienti && clienti.map(cliente => (
                                    <option key={cliente.id} value={cliente.id}>
                                        {cliente.ragione_sociale_alias}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={referenteInModifica?.nome ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={referenteInModifica?.cognome ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={referenteInModifica?.regione ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={referenteInModifica?.provincia ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={referenteInModifica?.cap ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Città: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={referenteInModifica?.citta ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={referenteInModifica?.indirizzo ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={referenteInModifica?.telefono ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={referenteInModifica?.mail ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> In fattura: </label>
                            <input className="checkbox" type="checkbox" {...register("in_fattura")} defaultChecked={referenteInModifica?.in_fattura ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Note: </label>
                            <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={referenteInModifica?.note ?? ""} />
                        </div>

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
            </div>}

            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}
            {/* Inserimento/Modifica dati mobile*/}

            {show && isMobile && <div className="NuovoReferente">

                {isError && <p> Formato mail errato </p>}

                <div className="w-screen m-auto">
                    <form className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4" onSubmit={handleSubmit(sulClick)}>

                        <div className="flex flex-col justify-center items-center mb-6">
                            <h1 className="mt-4 text-2xl font-semibold"> {!!referenteInModifica ? "MODIFICA I DATI DEL REFERENTE" : "INSERISCI I DATI DEL REFERENTE"} </h1>
                        </div>

                        <div className="divider" />

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2">Cliente: </label>
                            <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("cliente")} defaultValue={referenteInModifica?.cliente ?? ""} >
                                <option key="0" value="">
                                    Seleziona il cliente
                                </option>
                                {clienti && clienti.map(cliente => (
                                    <option key={cliente.id} value={cliente.id}>
                                        {cliente.ragione_sociale_alias}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Nome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Nome" {...register("nome")} defaultValue={referenteInModifica?.nome ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Cognome: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cognome" {...register("cognome")} defaultValue={referenteInModifica?.cognome ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Regione: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Regione" {...register("regione")} defaultValue={referenteInModifica?.regione ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Provincia: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Provincia" {...register("provincia")} defaultValue={referenteInModifica?.provincia ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Cap: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Cap" {...register("cap")} defaultValue={referenteInModifica?.cap ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Città: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Città" {...register("citta")} defaultValue={referenteInModifica?.citta ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Indirizzo: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Indirizzo" {...register("indirizzo")} defaultValue={referenteInModifica?.indirizzo ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Telefono: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="text" placeholder="Telefono" {...register("telefono")} defaultValue={referenteInModifica?.telefono ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Mail: </label>
                            <input className="md:w-2/3 input input-bordered input-xs w-full max-w-xl" type="email" placeholder="Mail" {...register("mail")} defaultValue={referenteInModifica?.mail ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> In fattura: </label>
                            <input className="checkbox" type="checkbox" {...register("in_fattura")} defaultChecked={referenteInModifica?.in_fattura ?? ""} />
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <label className="md:w-1/3 text-right mr-2"> Note: </label>
                            <textarea className="md:w-2/3 textarea textarea-bordered textarea-md w-full max-w-xl min-h-40 max-h-40" type="text" placeholder="Note" {...register("note")} defaultValue={referenteInModifica?.note ?? ""} />
                        </div>

                        <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={indietroTasto}> Indietro </button>
                            <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" type="submit"> Salva </button>
                        </div>

                    </form>
                </div>
            </div>}
        </>
    );
}
