import toast, { Toaster } from 'react-hot-toast';
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import sfondo from "./sfondo.jpg";
import logo from "./logo.png";
import pb from "./lib/pocketbase";

export default function Login() {
    const { mutate, isLoading, isError } = useMutation(login);
    const { register, handleSubmit, reset } = useForm();
    const navigate = useNavigate();
    const isPwa = window.matchMedia('(display-mode: standalone)').matches;

    useEffect(() => {
        if (isError) reset({ password: null })
    }, [isError])

    async function sulClick(data, event) {
        const buttonClicked = event.nativeEvent.submitter.value

        if (buttonClicked === "loginButt") {
            mutate(data)
        }
        else if (buttonClicked === "recPwd") {
            try {
                await pb.collection('user').requestPasswordReset(data.email)
                toast("Controlla la casella postale.")

            } catch (error) {
                toast.error("Inserisci la mail per recuperare la password.")
            }
        }
    }

    async function login(data) {
        try {
            await pb.collection('user').authWithPassword(data.email, data.password);
            navigate("/")
        } catch (error) {
            toast.error("Mail o password non corretti.")
        }
    }

    return (
        <div className="flex h-screen overflow-y-hidden bg-[url('./sfondo.jpg')] bg-cover bg-no-repeat">
            <div><Toaster /></div>
            <div className="w-full max-w-xs m-auto">
                {/* <form className={`bg-white rounded px-8 pt-6 pb-8 mb-4" ${isPwa?'':'shadow-md'}`} onSubmit={handleSubmit(sulClick)}> */}
                <form className={`bg-white rounded px-8 pt-6 pb-8 mb-4 shadow-md`} onSubmit={handleSubmit(sulClick)}>
                    <img src={logo} />
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Username
                        </label>
                        <input className="input input-bordered input-sm w-full max-w-xs" type="email" placeholder="email" autoComplete="email" {...register("email")} />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Password
                        </label>
                        <input className="input input-bordered input-sm w-full max-w-xs" type="password" placeholder="password" autoComplete="password" {...register("password")} />
                    </div>
                    <div className="flex items-center justify-between mb-4">
                        <button className="btn btn-outline btn-sm self-center" type="submit" value="loginButt" disabled={isLoading}> Login </button>
                        <button className="btn btn-link self-center btn-xs" type="submit" value="recPwd" disabled={isLoading}>Recupera password</button>
                    </div>
                    {isLoading && <p className="text-center"> Caricamento... </p>}
                </form>
            </div>
        </div>
    );
}
